import React from "react";
import {graphql, Link, StaticQuery} from "gatsby";

import {Collapse, Card, CardBody} from "reactstrap";
import {Helmet} from "react-helmet";
import logo from "../images/logo_new.svg";
import logoTalks from "../images/logo-talks.png";
import facebook from "../images/icons/facebook_new.svg";
import menu from "../images/icons/menu.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/scss/index.scss";

export default class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {collapse: false};
	}

	toggle = () => {
		this.setState({collapse: !this.state.collapse});
	};

	componentDidCatch(error, errorInfo) {
		if (window.Sentry) {
			window.Sentry.configureScope(scope => {
				Object.keys(errorInfo).forEach(key => {
					scope.setExtra(key, errorInfo[key]);
				});
			});
			window.Sentry.captureException(error);
		}
	}

	render() {
		return (
			<div className="container-fluid px-0">
				<StaticQuery
					query={graphql`
						{
							og_images: allFile(
								filter: {
									relativePath: {
										in: [
											"og.jpg"
										]
									}
								}
							) {
								edges {
									node {
										publicURL
									}
								}
							}
							site {
								siteMetadata {
									siteUrl
									title
									description
								}
							}
						}
					`}
					render={data => (
						<Helmet>
							<meta name="title" content={data.site.siteMetadata.title}/>
							<meta
								name="description"
								content={data.site.siteMetadata.description}
							/>
							<meta property="og:locale" content="cs_CZ"/>
							<meta property="og:type" content="website"/>
							<meta
								property="og:title"
								content={data.site.siteMetadata.title}
							/>
							<meta
								property="og:description"
								content={data.site.siteMetadata.description}
							/>
							<meta
								property="og:url"
								content={data.site.siteMetadata.siteUrl}
							/>
							<meta property="fb:app_id" content="993900171000614"/>
							{data.og_images.edges
								.map(edge => edge.node)
								.map(image => (
									<meta
										key={image.publicURL}
										property="og:image"
										content={`${data.site.siteMetadata.siteUrl}${image.publicURL}`}
									/>
								))}
						</Helmet>
					)}
				/>
				<header>
					<div className="container-heading">
						<div className="row align-items-center">
							<div className="col-9 col-md-6 col-lg-4">
								<h1 className="d-none">( ) /* AppParade */</h1>
								<Link to="/">
									<img
										src={this.props.page === "talks" ? logoTalks : logo}
										className="img-fluid logo-image"
										alt="AppParade"
									/>
								</Link>
							</div>
							<div className="col-3 col-md-6 col-lg-8 text-right">
								<nav className="d-none d-lg-block">
									<ul className="list-inline mb-0">
										{this.props.page === "talks" ? (
											<li className="list-inline-item mr-3">
												<Link className="badge badge-secondary" to="/">
													Events
												</Link>
											</li>
										) : (
											<>
												{/* <li className="list-inline-item mr-3">
													<Link className="badge badge-primary" to="/#register-app">
														Přihlásit appku
													</Link>
												</li> */}
												{/*<li className="list-inline-item mr-3">*/}
												{/*	<Link className="badge badge-secondary" to="/talks">*/}
												{/*		Talks*/}
												{/*	</Link>*/}
												{/*</li>*/}
												<li className="list-inline-item mr-3">
													<Link
														className="badge badge-secondary"
														to="/pravidla"
													>
														Pravidla
													</Link>
												</li>
												<li className="list-inline-item mr-3">
													<Link
														className="badge badge-secondary"
														to="/historie"
													>
														Historie
													</Link>
												</li>
											</>
										)}
										<li className="list-inline-item">
											<a
												href="https://www.facebook.com/AppParade/"
												rel="noopener noreferrer"
												target="_blank"
											>
												<img src={facebook} alt="Facebook"/>
											</a>
										</li>
									</ul>
								</nav>
								<img
									src={menu}
									alt="Menu"
									className="img-fluid d-lg-none mobile-menu"
									onClick={this.toggle}
								/>
							</div>
						</div>
					</div>
				</header>

				<Collapse isOpen={this.state.collapse}>
					<Card>
						<CardBody className="text-center">
							<nav className="mt-0">
								<ul className="d-flex flex-column list-md-inline mb-0">
									{this.props.page === "talks" ? (
										<li className="list-inline-item mr-md-3">
											<Link className="mb-3 badge badge-secondary" to="/events">
												Events
											</Link>
										</li>
									) : (
										<>
											{/* <li className="list-inline-item mr-md-3">
												<Link
													className="mb-3 badge badge-primary"
													to="/#register-app">
													Přihlásit appku
												</Link>
											</li> */}
											<li className="list-inline-item mr-md-3">
												<Link
													className="mb-3 badge badge-secondary"
													to="/pravidla"
												>
													Pravidla
												</Link>
											</li>
											<li className="list-inline-item mr-md-3">
												<Link
													className="mb-3 badge badge-secondary"
													to="/historie"
												>
													Historie
												</Link>
											</li>
										</>
									)}
									<li className="list-inline-item">
										<a
											href="https://www.facebook.com/AppParade/"
											rel="noopener noreferrer"
											target="_blank"
										>
											<img src={facebook} alt="Facebook"/>
										</a>
									</li>
								</ul>
							</nav>
						</CardBody>
					</Card>
				</Collapse>

				<main>{this.props.children}</main>

				<hr/>

				<footer>
					<div className="container-heading mt-5 mb-5 d-none d-md-block">
						<div className="row">
							<div className="col-sm-6">
								<p className="mb-0 slash">
									<a
										href="https://www.megumethod.com/"
										rel="noopener noreferrer"
										target="_blank"
										className="text-light"
									>
										Created by MeguMethod in&nbsp;
										{new Date().getFullYear()}
									</a>
								</p>
							</div>
							<div className="col-sm-6 text-right ">
								<ul className="list-inline mb-0">
									<li className="list-inline-item mr-3">
										<a
											className="badge float-left mt-2 "
											href="mailto:info@appparade.cz"
										>
											info@appparade.cz
										</a>
										<a
											href="https://www.facebook.com/AppParade/"
											rel="noopener noreferrer"
											target="_blank"
										>
											<img className="mt-3"
												 src={facebook} alt="Facebook"/>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>

					<div className="container-heading mt-5 mb-5 d-md-none">
						<div className="row">
							<div className="col-sm-6 mb-4">
								<span className="badge  float-left mt-2">
									info@appparade.cz
								</span>
								<a
									href="https://www.facebook.com/AppParade/"
									rel="noopener noreferrer"
									target="_blank"
								>
									<img className="mt-3"
										 src={facebook} alt="Facebook"/>
								</a>
							</div>
							<div className="col-sm-6">
								<p className="mb-0 slash">
									<a
										id="megumethodcom-link"
										href="https://www.megumethod.com/"
										rel="noopener noreferrer"
										target="_blank"
										className="text-light"
									>

										Created by MeguMethod in&nbsp;
										{new Date().getFullYear()}
									</a>
								</p>
							</div>
						</div>
					</div>
				</footer>
			</div>
		);
	}
}
